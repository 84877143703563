import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Container from "../components/Container"
import DotSection from "../components/DotSection"
import Hero from "../components/Hero"
import HeroLead from "../components/HeroLead"
import HeroTitle from "../components/HeroTitle"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import SubTitle from "../components/SubTitle"
import Text from "../components/Text"
import Title from "../components/Title"
import links from "../content/links"
import metaImg from "../images/luottokorttipuhelin.svg"

export default (): JSX.Element => (
  <Layout>
    <SEO
      title="Ohjelmistokehitys useille alustoille ja tarpeisiin"
      description="Tarjoamme asiakkaillemme käyttäjille suunnattuja front-end web ohjelmointia ja suunnittelua, joilla voidaan saavuttaa laaja ja muuttuva web ratkaisu."
      pageImage={metaImg}
      pageUrl={links.software}
    />

    <section id="hero">
      <Hero>
        <HeroTitle>
          <span className="block">
            <h1>Ohjelmistokehitys</h1>
          </span>
          <span className="block text-indigo-400">palvelut</span>
        </HeroTitle>
        <HeroLead>
          Tärkeintä ohjelmistokehityksessä on tuntea ohjelmoitavat alustat,
          niille sopivat teknologiat ja kulloinkin vallitsevat trendit.
        </HeroLead>
      </Hero>
    </section>

    <section id="lead">
      <DotSection dotPosition="top-right">
        <div className="relative lg:grid lg:grid-cols-2 lg:gap-x-8">
          <div>
            <Text>
              Tietokone, älypuhelin, auto, radio ja jopa rannekello – kaikkia
              näitä voidaan ohjata ohjelmistojen avulla. Nykyisin on jopa
              mahdollista tarkistaa jääkaapin sisältö ostosreissun aikana.
              Yhteiskunta on ottanut jättimäisiä harppauksia kohtia
              digitalisaatiota ja vaikka olemmekin edenneet pitkälle viime
              vuosina, ovat markkinat vasta alkamassa avautua yrittäjille.
              Tietotekniikka ja kasvanut ohjelmistokehitys ovat muuttaneet
              markkinoita ja rajana onkin nykyisin enää mielikuvitus. Tämä
              muutos on tuonut tullessaan uusia mahdollisuuksia palveluiden,
              tuotteiden, teknologioiden ja yhteiskunnan kehitykselle.
            </Text>
            <Text>
              Kuten maailma, myös ohjelmistokehitys on koko ajan kasvava ja
              muuttuva. Tärkeintä kehityksessä onkin tuntea ohjelmoitavat
              alustat, niille sopivat teknologiat ja kulloinkin vallitsevat
              trendit. Näitä yhdistämällä voimme tarjota tehokkaita ratkaisuja
              monipuoliselle ja suositulle alustavalikoimalle.
            </Text>
            <Text>
              Ohjelmistokehitys on tarjoamiemme palveluiden kulmakivi.
              Yrityksemme ammattitaito perustuu johtaviin, moderneihin ja
              arvostetuimpiin ratkaisuihin ohjelmistojen valmistuksessa.
              Tavoitteenamme on ratkaista kaikki asiakkaiden ongelmat –
              riippumatta siitä olivatpa ne minkä kokoisia tai muotoisia.
              Sataprosenttisella tyytyväisyystakuulla.
            </Text>
          </div>
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 mt-6 lg:mt-0">
            <StaticImage
              src="../images/3-right-trans-color.svg"
              alt="Mobiilikehitys elementtejä"
            />
          </div>
        </div>
      </DotSection>
    </section>

    <section id="order" className="bg-gray-800">
      <Container>
        <div className="relative lg:grid lg:grid-cols-2 lg:gap-x-8">
          <div>
            <StaticImage
              src="../images/4-left-trans.svg"
              alt="Ympyrädiagrammeja viivakaavion päällä"
            />
          </div>
          <div>
            <Title tag="h2" textColor="text-white">
              Tilaustyö vai valmisohjelmisto
            </Title>
            <Text textColor="text-white">
              Sopiiko sinun tarpeisiisi paremmin mittatilaustyö vai riittääkö
              valmiin ohjelmiston muokkaaminen? Vanha vitsi on, että jokainen
              sävel on jo soitettu, mutta vaaditaan taiteilija luomaan
              mestariteos niistä. Sama pätee ohjelmistoihin. Käytännössä pyörää
              ei kannata siis todellakaan keksiä uudelleen ja joihinkin
              tarpeisiin on olemassa jo sopivia pohjia tuotetta varten.
              Esimerkiksi ohjelmistot ja sovellukset noudattavat yleensä
              samanlaista sivurakennetta ja koodi perustuu samanlaiseen
              toimintamalliin. Sama pohja ei kuitenkaan tarkoita samanlaisuutta,
              vaan yhä edelleen tuote kustomoidaan asiakkaan tarpeiden mukaan.
            </Text>
            <Text textColor="text-white">
              Tärkeimpänä ohjelmistokehityksessä ovat asiakkaan omat tarpeet ja
              toiveet ja ne määrittävät sen miten ohjelmisto tuotetaan.
              Tarvittaessa luomme ohjelmiston aivan alusta alkaen jolloin
              pystymme vaikuttamaan kaikkiin yksityiskohtiin heti kehityksen
              alkamisesta lähtien. Aikataulullisesti ohjelmistokehitys saattaa
              olla tehokkaampaa hyödyntää jo olemassa olevia ohjelmistoja.
              Nykyisin lähes kymmenen projektia kymmenestä onkin yhdistelmä jo
              olemassa olevista ohjelmista, palveluntarjoajista ja näiden
              yhdistämisestä yrityksen omaan järjestelmään tilaustyönä.
              Käytännössä kyse on hybridimallista, jossa hyödynnetään vanhaa ja
              rakennetaan uutta. Näin saadaan valmistettua ohjelmistoja, jotka
              täyttävät yritysten tarpeet, samaan aikaan kehittämättä pyörää
              uudelleen.
            </Text>
          </div>
        </div>
      </Container>
    </section>

    <section id="steps">
      <DotSection dotPosition="top-right">
        <div>
          <div className="grid lg:grid-cols-3">
            <div className="col-span-2 lg:mb-3">
              <SubTitle tag="p">Miten ohjelmistoprojektit etenevät?</SubTitle>
              <Title tag="h2">Ohjelmistokehitys vaiheet</Title>
              <Text>
                Alla käymmekin läpi yleisimmät ohjelmistokehitys vaiheet. Vaikka
                kirjoitus onkin esitetty ideaalisessa aikajärjestyksessä,
                monesti joudutaan palaamaan aikaisempaan vaiheeseen ja
                hienosäätämään tuotetta. Näin käy usein esimerkiksi
                testausvaiheessa, kun löydetään selkeitä ja tuottavia
                parannuskohteita.
              </Text>
            </div>
          </div>
          <Text>
            Jokainen projekti on omanlaisensa yksilö ja satavarmaa kehitystapaa
            ei voi eikä edes kannata antaa ennen kuin projektia on työstetty
            hetken aikaa. Yleensä kaikki alkaa asiakkaan ideasta, joka jalostuu
            työn edetessä. Siksi on oltava avoin uusille ideoille ja
            kehityssuunnille, vaikka omista näkemyksistä on usein vaikea
            joustaa. Käytännössä ohjelmisto voidaan tuottaa kolmessa vaiheessa
            ulos. Ensimmäinen versio on prototyyppi – äärimmäisen karu ja
            yksinkertainen versio, joka näyttää ohjelmiston ja konseptin
            toimivuuden. Tästä parantamalla saadaan MVP eli pienin mahdollinen
            julkaisuvalmis ohjelmisto, joka suunnattu tyydyttämään ensimmäisten
            asiakkaiden tarpeet ja keräämään palautetta tuotekehitystä varten.
            Viimeisenä on luonnollisesti palautteen ja kehittämisen kautta luotu
            valmis ja hiottu ohjelmisto.
          </Text>
          <Text>
            Ohjelmistokehitys koostuu useasta eri vaiheesta, joista kaikissa
            asiakkaalla on keskeinen rooli. Hyvä lopputulos ei synny pelkästään
            työntämällä ohjelmistoideaa suoraan ohjelmistotalolle. Ensimmäisenä
            asiakkaan kannattaa itse määritellä ohjelmistonsa tarpeet ja
            käyttökohteet. Sen jälkeen suunnittelu ja kehitys tapahtuvat
            yhteistyössä asiakkaan kanssa. Suunnittelun aikana on tärkeää pitää
            kiinni korkealaatuisista menetelmistä, mutta vieläkin tärkeämpää on
            avoin vuoropuhelu osapuolten välillä ohjelmistojen suunnittelun
            pohjana. Näin saadaan varmistettua tyytyväinen asiakas ja toimiva
            ohjelmisto.
          </Text>
        </div>
      </DotSection>
    </section>

    <section id="problems">
      <DotSection dotPosition="bottom-left">
        <div>
          <SubTitle tag="p">Mihin käyttötarkoitukseen?</SubTitle>
          <Title tag="h2">Ongelmaan ratkaisu</Title>
          <Text>
            Ohjelmistokehityksen suunnittelussa on hyvä ensimmäiseksi kysyä,
            mihin ongelmaan ohjelmistolla yritetään hakea ratkaisua.
            Ohjelmistokehityksellä voidaan luoda tehokkuutta esimerkiksi
            automatisoimalla yrityksen prosesseja, tai ohjelmiston tavoitteena
            voi olla luoda uusia palveluja ratkaisemalla asiakkaiden ongelmia.
            Olennaista on löytää ongelma joka tarvitsee ja on tuottavaa
            ratkaista. Siksi ideaa onkin hyvä myös hyvä sparrata muiden ihmisten
            kanssa. Potentiaaliset asiakkaan tai ainakin ohjelmistoasiantuntijat
            kykenevät antamaan arvokasta palautetta alkuperäisen idean
            kehittämiseen, sillä monesti heidän näkökulmansa eroaa
            suunnittelijan näkemyksistä. Näin he kykenevät kertomaan omia,
            ensiarvoisen tärkeitä tarpeitaan käyttäjien näkökulmasta.
          </Text>
          <Text>
            Lisäksi kannattaa perehtyä mahdollisiin asiakasryhmiin ja
            markkinoilla jo olemassa oleviin ohjelmistoihin. Tuotteelle pitää
            aina olla markkinaryhmä ja esimerkiksi tarkoin määritellyille
            kohderyhmille suunniteltavat tuotteet voidaan räätälöidä aina
            asiakasryhmille sopiviksi. Entä jos vastaava ohjelmisto on jo
            käytössä? Tämä ei ole ongelma. Olemassa oleva tuote kertoo myös,
            että kyseinen ohjelmisto on toimiva ja kannattava. Siksi pieni
            tutustuminen jo olemassa olevaan, voi avata suunnittelun osalta
            uusia ovia ja paljastaa mahdollisia ongelmakohtia.
          </Text>
        </div>
      </DotSection>
    </section>

    <section id="ohjelmistosuunnittelu">
      <DotSection dotPosition="top-right">
        <div>
          <SubTitle tag="p">Ohjelmistovaatimukset!</SubTitle>
          <Title tag="h2">Ohjelmistosuunnittelu</Title>
          <Text>
            Kun idea on saatu muokattua ja ongelmaa aletaan lähestyä
            suunnitteluvaiheessa, tärkeintä on rautalankamallin esittäminen eli
            mitä vähimmäisvaatimuksia ohjelmistolla on. On erityisen tärkeää
            kartoittaa mitkä ovat ohjelmiston kaikista keskeisimmät
            ominaisuudet, joita ilman ohjelmisto ei ratkaise haluamaasi
            ongelmaa. Tästä eteenpäin ohjelmistokehityksen suunnittelu kulkee
            käytännössä rinnakkain kehitystyön kanssa ja projekti pallottelee
            näiden kahden välillä.
          </Text>
          <Text>
            On äärimmäisen tärkeää kyetä esittämään vaadittavat ominaisuudet ja
            toiveet niin, että asiakas, suunnittelija ja kehittäjä jakavat saman
            vision. Siksi esitettävässä rautalankamallissa on hyvä olla
            esimerkiksi ajatuskartta ominaisuuksista, käyttöliittymän
            mahdollisesta visuaalisesta ulkoasusta ja huomioita tärkeimmistä
            kehityskohteista. Suunnitelma saa olla erittäin yksinkertainen
            raakaversio ja piirrokset voivat olla lyijykynällä sekunnissa
            piirrettyjä. Pääasia on, että kaikilla on edessään samanlainen
            esitys tavoitteista.
          </Text>
          <Text>
            Ohjelmistokehityksen suunnitteluvaiheessa kannattaa myös huomioida
            eri kohderyhmät. Erilaiset käyttötottumukset ja
            käyttöliittymäratkaisut sekä visuaalinen ilme vaihtelevat
            käyttäjäryhmien mukaan. Käytännössä tähän tulisi olla kiinnitetty
            huomiota siis jo alkuperäistä ideaa hahmoteltaessa.
          </Text>
        </div>
      </DotSection>
    </section>

    <section id="ohjelmistosuunnittelu">
      <DotSection dotPosition="bottom-left">
        <div>
          <SubTitle tag="p">Varsinaisen ohjelmiston valmistaminen</SubTitle>
          <Title tag="h2">Kehitys</Title>
          <Text>
            Ohjelmistokehityksen ensimmäiseksi tavoitteeksi otetaan MVP eli
            minimal viable product. Tuote, joka ratkaisee ongelman ja tekee
            tehtävänsä, mutta ei tee vielä mitään sen hienompaa. Tämän jälkeen
            ohjelmistoon aletaan valmistamaan hienompia ja hiotumpia
            ominaisuuksia kierros kierrokselta. Kuten ohjelmistosuunnittelussa,
            myös ohjelmistokehityksessä on ensiarvoisen tärkeää, että
            ohjelmistoja valmistetaan yhdessä asiakkaiden kanssa. Tiivis
            yhteistyö ja yhteydenpito ovat ainoita tapoja varmistaa
            valmistettavan tuotteen korkea laatu.
          </Text>
          <Text>
            Ohjelmistokehitys ja ohjelmistosuunnittelu ovat iterointia eli
            kyseessä on toistuva prosessi, jolla pyritään parantamaan tuotetta
            kerta kerralta. Ymmärrettävää on, että asiakas haluaisi valmiin
            tuotteen ensimmäisellä yrittämällä. Kokemus on kuitenkin opettanut,
            että valmiin ja ennen kaikkea toimivat ohjelmiston luominen vaatii
            useita toistoja. Silti tätä kehitystä voidaan nopeuttaa valitsemalla
            oikeanlaisia ja projektille soveltuvia tekniikoita. Näistä hyvä
            esimerkki on ketterä kehitys, joka soveltuu hyvin moniosaisten
            ohjelmistojen tuottamiseen. Ketterästä kehityksestä kerrotaan
            tarkemmin alempana.
          </Text>
        </div>
      </DotSection>
    </section>

    <section id="testaus">
      <Container>
        <div className="grid grid-cols-2 space-x-3">
          <div>
            <SubTitle tag="p">Käyttötoiminan varmistaminen</SubTitle>
            <Title tag="h2">Testaus</Title>
            <Text>
              Testaus on käytännössä päätös ohjelmistosuunnittelu ja
              kehityskierrokselle. Ohjelmistokehityksen testauksen avulla
              pyritään todistamaan suunnittelun ja kehityksen ratkaisut
              toimiviksi ja mahdollisesti havaitsemaan mahdollisia
              lisäkehityskohteita. Mikäli ohjelma on suunnattu kuluttajien tai
              käyttäjien ohjelmistoksi, on erittäin hyvä löytää testausryhmä tai
              testihenkilöitä, jotka ovat valmiina testaamaan ohjelmistoa.
              Aikainen testaus auttaa löytämään tehokkaasti ohjelmointivirheitä
              tai ohjelmistoon liittyviä strategisia ongelmia. Käyttäjäpalaute
              ja sen perusteella tehdyt päätökset ohjelmistokehityksestä ovat
              yksi tärkeimmistä – ellei jopa se tärkein kehitystyökalu.
            </Text>
            <Text>
              Vaikka halu saada tuote valmiiksi onkin kova, vajavaisen
              ohjelmiston julkistaminen ei ole ikinä kannattavaa. Yleensä
              jälkikäteen tehtävät ohjelmistomuutokset ovat kohtuullisen
              hintavia ja aikaa vieviä verrattuna niiden tekemiseen
              alkuperäisessä kehitysvaiheessa. Siksi onkin oltava avoin
              ehdotuksille ja mahdollisille muutoksille. Ikinä ei ole liian
              myöhäistä tehdä selviäkin muutoksia ohjelmistoon tai
              käyttöliittymään.
            </Text>
          </div>
          <div>
            <SubTitle tag="p">Kauppaan vai verkkoon</SubTitle>
            <Title tag="h2">Julkaisu</Title>
            <Text>
              Mikäli ohjelmisto on tarkoitettu muuhun kuin yrityksen sisäiseen
              käyttöön, on seuraavaksi edessä sovelluksen julkaisu. Ennen
              julkaisua ohjelmiston täytyy olla testattu läpikotaisin eikä se
              saa sisältää käyttökokemusta heikentäviä bugeja. Jos yrityksellä
              on tarkoitus markkinoida ohjelmaa, niin sitä varten kannattaa
              luoda mahdollisia strategioita ohjelmiston markkinointiin.
            </Text>
            <Text>
              Halutessaan asiakas voi käyttää yrityksensä omaa
              markkinointiosastoa tai esimerkiksi ulkoista
              markkinointitoimistoa, mutta luonnollisesti yrityksemme auttaa
              mielellään jo ohjelmistokehityksen aikana markkinoinnillisissa
              tehtävissä, strategian rakentamisessa ja julkaisuissa.
            </Text>
          </div>
        </div>
      </Container>
    </section>

    <section id="yllapito">
      <DotSection dotPosition="top-right">
        <div>
          <SubTitle tag="p">Palveinratkaisut</SubTitle>
          <Title tag="h2">Ylläpito ja kehitys</Title>
          <Text>
            Ohjelma on nyt julkaistu ja levityksessä. Tässä kohtaa ei kannata
            unohtaa taustalla pyörivää ohjelmistoa, jonka infrastruktuuri on
            tärkeä pitää pystyssä eli huollettuna ja ajan tasalla. Tarjoamme
            ohjelmillemme kaiken ammattitaitomme kokonaisen arkkitehtuurin
            ylläpitämiseen teknologiasta riippumatta. Ohjelmiston
            ylläpitovaatimukset riippuvat suuresti projektista ja ohjelmistosta
            sekä niiden suosiosta. Siksi ylläpito sovitaankin yleensä
            tapauskohtaisesti ja asiakkaan tarpeen mukaan, mutta mikäli nuo
            tarpeet muuttuvat olemme aina valmiita auttamaan.
          </Text>
          <Text>
            Alustasta riippuen päivityksiä voidaan tehdä joko paikallisesti tai
            etänä. Tavallisesti käytämme asiakkaiden omia servereitä, mutta
            tarpeen vaatiessa voimme auttaa myös serverien päivittämisessä ja
            saattamisessa ajan tasalle asiakkaan tarpeiden mukaan. Siksi onkin
            hyvä pohtia ylläpitoon liittyviä seikkoja kun ohjelmistokehitys on
            vaiheessa, jotta serverit ja vaadittava infrastruktuuri on toimiva
            ja kunnossa jo ohjelmistoa julkaistaessa.
          </Text>
          <Text>
            Vaikka nyt asiakkaalla onkin käytössään valmis ohjelmisto, ei työ
            lopu vielä tähän. Yleensä tarpeet ja käyttötarkoitukset muuttuvat
            tai laajenevat. Ohjelmistoa voidaankin päivittää muuttuvien tai
            ilmenneiden tarpeiden mukaan. Valmis ja toimiva ohjelmisto luo
            oivallisen alustan edulliselle ja nopealle ohjelmistokehitykselle ja
            kehitys alkaa uudelleen – joskin tällä kertaa prosessi etenee
            huomattavasti nopeammin.
          </Text>
        </div>
      </DotSection>
    </section>

    <section id="agile">
      <DotSection dotPosition="bottom-left">
        <div>
          <SubTitle tag="p">Agile</SubTitle>
          <Title tag="h2">Ketterä kehitys</Title>
          <Text>
            Ketterä ohjelmistokehitys on prosessikehys, jonka läpi suurin osa
            nykypäivän ohjelmistoprojekteista kulkee riippumatta
            ohjelmistotalosta. Ketterässä kehityksessä asetetaan ohjelmiston
            kehitys etusijalle mahdollistamalla nopea reagointi vaadittaviin
            muutoksiin. Tällaiseen prosessikehitykseen kuuluvia metodeja on
            useita, mutta suosituin niistä on Scrum -malli.
          </Text>
          <Text>
            Ketterässä ohjelmistokehityksessä pyritään minimoimaan riskejä
            jakamalla ohjelmistokehitys lyhyisiin osaprojekteihin tai
            iteraatioihin. Jokainen osa tuotetaan nopealla aikataululla ja se
            sisältää kaikki uusien toimintojen julkaisemiseen vaadittavat
            tehtävät.
          </Text>
          <Text>
            Vaikka osaprojekti ei välttämättä aina lisää toiminnallisuutta,
            pyritään jokaisen osan jälkeen julkaisuvalmiiseen ohjelmistoon. Näin
            ohjelmisto on käyttö- ja testauskelpoinen, mutta sinne voidaan
            lisätä koko ajan uusia ominaisuuksia ja samalla muokata vanhoja.
            Tässä kehityksen muodossa arvostetaan suoraa suullista viestintää
            enemmän kuin kirjoitettuja dokumentteja. Kehityksen aikana
            tapahtuvat muutokset ohjelmistoihin ovat enemmänkin sääntö kuin
            poikkeus, siksi ketterä kehitys on usein helpompi ja joustavampi
            tapa tuottaa ohjelmistoja.
          </Text>
        </div>
      </DotSection>
    </section>

    <section id="tech" className="bg-gray-800">
      <Container>
        <Title tag="h2" textColor="text-white">
          Teknologiat
        </Title>
        <ul className="text-white">
          <li>
            <span className="font-bold">Web</span>: HTML, CSS, Node.js,
            React.js, Java, Python, PHP, Ruby, Ruby on Rails, Laravel, Spring,
            Vue.js, Backbone, Meteor, WordPress, Laravel, AngularJS ja jQuery
          </li>
          <li>
            <span className="font-bold">Mobiili</span>:: React Native, Java,
            Swift, Cordova
          </li>
          <li>
            <span className="font-bold">Muut</span>:: Linux, Python, Go
          </li>
        </ul>
      </Container>
    </section>

    <section id="open-source">
      <DotSection dotPosition="top-right">
        <div>
          <SubTitle tag="p">Open source</SubTitle>
          <Title tag="h2">Avoin lähdekoodi</Title>
          <Text>
            Ohjelmistokehitysprojektit ovat lähes aina kokonaan valmistettavissa
            avoimen lähdekoodin moduulien päälle. Tämä pitää hinnat edullisina
            verrattuna esimerkiksi ohjelmistolisenssejä vaativiin
            ohjelmistoihin. Vaikka näissä kehitys olisikin halpaa, usein
            lisenssimaksut nostavat lopullisen hinnan korkealle.
          </Text>
          <Text>
            Avoimella lähdekoodilla pystytään takaamaan kilpailukykyiset hinnat,
            mutta mikään ohjelmisto ei ole suoraan avoimesti saatavilla.
            Vaaditaankin ammattitaitoa, jotta avoimesta lähdekoodista saadaan
            valmistettua asiakkaalle räätälöity ohjelmisto. Usein
            ohjelmistokehitys onkin vanhan yhdistelyä ja samalla uuden koodin
            kirjoittamista.
          </Text>
          <Text>
            Usein avoin lähdekoodi on myös luotettavampi kuin suljettu
            lähdekoodi, koska kuka tahansa voi käydä läpi avoimen lähdekoodin
            omista ammatillisista lähtökohdistaan ja parannella sitä omasta
            erikoistumisnäkökulmastaan tai huomata muita koodin liittyviä
            riskejä tai vikoja.
          </Text>
        </div>
      </DotSection>
    </section>
  </Layout>
)
